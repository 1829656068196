import { useTranslation } from 'react-i18next';
import { Grid2 as Grid } from '@mui/material';
import { keys, map } from 'lodash';
import { FC, useMemo } from 'react';

import FormCard from '@/components/ui/FormCard';
import TextInputGhost from '@/components/ghosts/TextInputGhost';
import SelectField from '@/components/formControl/SelectField';
import TextField from '@/components/formControl/TextField';

import useTranslatedEnum from '@/hooks/useTranslatedEnum';
import {
  CustomerForm,
  CustomerFormProps,
  EmploymentStatus,
  LegalForm,
  PensionInsuranceStatus,
} from '@/types';
import { useFormikContext } from 'formik';
import { getFormKey, showIf } from '@/utils/system';
import { BBesGGroups, customerVisibilityKeys } from '@/constants/form';

const WorkForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { values } = useFormikContext<CustomerForm>();

  const { t } = useTranslation();
  const [employmentStatus] = useTranslatedEnum(
    'EmploymentStatus',
    EmploymentStatus,
  );
  const [legalForm] = useTranslatedEnum('LegalForm', LegalForm);
  const [pensionInsuranceStatus] = useTranslatedEnum(
    'PensionInsuranceStatus',
    PensionInsuranceStatus,
  );

  const jobTitleGroupedItems = useMemo(() => {
    return map(keys(BBesGGroups), (key) => {
      return {
        label: t(`enums:BBeSG.${key}`),
        items: BBesGGroups[key as keyof typeof BBesGGroups],
      };
    });
  }, [t]);

  return (
    <FormCard>
      <Grid container columns={2} rowSpacing={6} columnSpacing={14}>
        <Grid size={1}>
          <SelectField
            fieldName={getFormKey(formKey, 'employment_status')}
            items={employmentStatus}
            label={t('customer:newCustomer.form.employmentStatus.label')}
          />
        </Grid>
        {!values[formKey]?.employment_status && (
          <>
            <Grid size={1}>
              <TextInputGhost />
            </Grid>
            <Grid size={1}>
              <TextInputGhost />
            </Grid>
          </>
        )}
        {showIf(
          values[formKey],
          'employment_status',
          customerVisibilityKeys.jobTitle,
          <Grid size={1}>
            <TextField
              inline
              hideErrorMessage
              fieldName={getFormKey(formKey, 'job_title')}
              label={t('customer:newCustomer.form.jobTitle.label')}
            />
          </Grid>,
        )}
        {showIf(
          values[formKey],
          'employment_status',
          customerVisibilityKeys.jobTitleBBesG,
          <Grid size={1}>
            <SelectField
              items={jobTitleGroupedItems}
              fieldName={getFormKey(formKey, 'job_title')}
              label={t('customer:newCustomer.form.jobTitleBBesG.label')}
            />
          </Grid>,
        )}
        {showIf(
          values[formKey],
          'employment_status',
          customerVisibilityKeys.legalForm,
          <Grid size={1}>
            <SelectField
              fieldName={getFormKey(formKey, 'legal_form')}
              label={t('customer:newCustomer.form.legalForm.label')}
              items={legalForm}
            />
          </Grid>,
        )}
        {showIf(
          values[formKey],
          'employment_status',
          customerVisibilityKeys.employeeCount,
          <Grid size={1}>
            <TextField
              inline
              hideErrorMessage
              fieldName={getFormKey(formKey, 'employee_count')}
              label={t('customer:newCustomer.form.employeeCount.label')}
            />
          </Grid>,
        )}
        {showIf(
          values[formKey],
          'employment_status',
          customerVisibilityKeys.revenueAndProfit,
          <Grid size={1}>
            <TextField
              inline
              hideErrorMessage
              fieldName={getFormKey(formKey, 'revenue')}
              label={t('customer:newCustomer.form.revenue.label')}
            />
          </Grid>,
        )}
        {showIf(
          values[formKey],
          'employment_status',
          customerVisibilityKeys.revenueAndProfit,
          <Grid size={1}>
            <TextField
              inline
              hideErrorMessage
              fieldName={getFormKey(formKey, 'profit')}
              label={t('customer:newCustomer.form.profit.label')}
            />
          </Grid>,
        )}
        {showIf(
          values[formKey],
          'employment_status',
          customerVisibilityKeys.legalForm,
          <Grid size={1}>
            <SelectField
              fieldName={getFormKey(formKey, 'pension_insurance_status')}
              items={pensionInsuranceStatus}
              label={t(
                'customer:newCustomer.form.pensionInsuranceStatus.label',
              )}
            />
          </Grid>,
        )}
      </Grid>
    </FormCard>
  );
};

export default WorkForm;
