import { Stack } from '@mui/material';
import { FC, useCallback } from 'react';
import {
  NavigationBarItemType,
  NavigationBarProps,
} from '@/components/ui/NavigationBar/types';
import NavigationBarItem from '@/components/ui/NavigationBar/NavigationBarItem';
import { map, some } from 'lodash';
import { useMatches } from 'react-router-dom';

import style from './style';
import { Routes } from '@/constants/routes';

const NavigationBar: FC<NavigationBarProps> = ({ items, onClick }) => {
  const matches = useMatches();

  const isActive = useCallback(
    (path: string) => {
      if (path === Routes.Root) {
        return false;
      }

      return some(matches, (match) => {
        return match.pathname.includes(path);
      });
    },
    [matches],
  );

  const renderItem = useCallback(
    (item: NavigationBarItemType, i: number) => {
      return (
        <NavigationBarItem
          key={i}
          {...item}
          active={isActive(item.path)}
          onClick={onClick}
        />
      );
    },
    [isActive, onClick],
  );

  return <Stack sx={style.container}>{map(items, renderItem)}</Stack>;
};

export default NavigationBar;
