import { TableCell, TableRow } from '@mui/material';
import CellSelectField from '@/components/formControl/CellSelectField';
import CellTextField from '@/components/formControl/CellTextField';
import IconButton from '@/components/ui/IconButton';
import { FC } from 'react';
import { LifeInsuranceList } from '@/constants/insurances';
import { getFormKey } from '@/utils/system';

export interface LifeInsuranceRowProps {
  formKey: string;
  index: number;
  onDelete: (index: number) => void;
}

const LifeInsuranceRow: FC<LifeInsuranceRowProps> = ({
  formKey,
  index,
  onDelete,
}) => {
  // @todo: implement api
  // const handleCreate = () => {
  //   if (
  //     value.annual_payment &&
  //     value.type &&
  //     value.monthly_payment &&
  //     value.provider
  //   ) {
  //     addInsurance({
  //       category: 'life_financial',
  //       ...value,
  //     });
  //   }
  // };

  const handleDelete = () => {
    onDelete(index);
  };

  return (
    <TableRow>
      <CellSelectField
        tableCellProps={{ width: 274 }}
        fieldName={getFormKey(formKey, 'type')}
        items={LifeInsuranceList}
      />
      <CellTextField
        tableCellProps={{ width: '20%' }}
        fieldName={getFormKey(formKey, 'provider')}
      />
      <CellTextField
        tableCellProps={{ width: 127 }}
        fieldName={getFormKey(formKey, 'monthly_payment')}
        endAdornment="€"
        valueEndAdornment="€"
      />
      <CellTextField
        tableCellProps={{ width: 127 }}
        fieldName={getFormKey(formKey, 'annual_payment')}
        endAdornment="€"
        valueEndAdornment="€"
      />
      <CellTextField
        tableCellProps={{ width: '20%' }}
        fieldName={getFormKey(formKey, 'info')}
      />
      <TableCell>
        <IconButton onClick={handleDelete} icon="Delete" variant="primary" />
      </TableCell>
    </TableRow>
  );
};

export default LifeInsuranceRow;
