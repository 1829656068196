import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextInput from '@/components/ui/TextInput';
import CTAButton from '@/components/ui/CTAButton';
import useDebounce from '@/hooks/useDebounce';

export interface CustomerFilterProps {
  onAddClick: () => void;
  // @todo(KAN-111): implement filters
  filterItems?: string[];
  loading?: boolean;
  onSearchValueChange?: (value: string) => void;
}

const CustomerFilter: FC<CustomerFilterProps> = ({
  onAddClick,
  loading,
  onSearchValueChange,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation();

  const debounceHandleTextChange = useDebounce((value: string) => {
    onSearchValueChange?.(value);
  }, 300);

  useEffect(() => {
    debounceHandleTextChange(searchValue);
  }, [debounceHandleTextChange, searchValue]);

  return (
    <Stack alignItems="flex-start" gap={6}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <TextInput
          icon="Search"
          value={searchValue}
          autoWidth
          onTextChange={setSearchValue}
          placeholder={t('admin:home.searchPlaceholder')}
        />
        <CTAButton
          icon="Plus"
          label={t('admin:home.addCustomer')}
          variant="secondary"
          onClick={onAddClick}
          loading={loading}
          autoWidth
        />
      </Stack>
    </Stack>
  );
};

export default CustomerFilter;
