import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  label: {
    fontWeight: 500,
  },
  container: {
    flex: 1,
    width: '100%',
  },
  error: {
    margin: 0,
    fontSize: '14px',
  },
  icon: {
    mr: 2,
  },
  input: {
    height: '58px',
  },
  inputInForm: {
    border: 'none',
  },
});

export default style;
