import { Table, TableBody } from '@mui/material';
import { FC } from 'react';
import {
  TableKeyValueData,
  TableKeyValueProps,
} from '@/components/ui/TableKeyValue/types';
import TableHeader from './TableHeader';
import KeyValueTableRow from '@/components/ui/TableKeyValue/KeyValueTableRow';
import { map } from 'lodash';
import style from './style';

const TableKeyValue: FC<TableKeyValueProps> = ({ data, header }) => {
  const renderRow = (item: TableKeyValueData, index: number) => {
    return <KeyValueTableRow data={item} key={index} />;
  };

  return (
    <Table sx={style.table}>
      {header && <TableHeader data={header} />}
      <TableBody>{map(data, renderRow)}</TableBody>
    </Table>
  );
};

export default TableKeyValue;
