import { ReactComponent as Checked } from './Checked.svg';
import { ReactComponent as Hidden } from './Hidden.svg';
import { ReactComponent as Visible } from './Visible.svg';
import { ReactComponent as Error } from './Error.svg';
import { ReactComponent as EmailLarge } from './EmailLarge.svg';
import { ReactComponent as CheckedLarge } from './CheckedLarge.svg';
import { ReactComponent as Exit } from './Exit.svg';
import { ReactComponent as Home } from './Home.svg';
import { ReactComponent as Section } from './Section.svg';
import { ReactComponent as Down } from './Down.svg';
import { ReactComponent as Plus } from './Plus.svg';
import { ReactComponent as Edit } from './Edit.svg';
import { ReactComponent as Search } from './Search.svg';
import { ReactComponent as Arrow } from './Arrow.svg';
import { ReactComponent as EditLarge } from './EditLarge.svg';
import { ReactComponent as Close } from './Close.svg';
import { ReactComponent as Filter } from './Filter.svg';
import { ReactComponent as Next } from './Next.svg';
import { ReactComponent as NextStep } from './NextStep.svg';
import { ReactComponent as Step } from './Step.svg';
import { ReactComponent as StepActive } from './StepActive.svg';
import { ReactComponent as StepSuccess } from './StepSuccess.svg';
import { ReactComponent as Prev } from './Prev.svg';
import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as Delete } from './Delete.svg';
import { ReactComponent as AddPrimary } from './AddPrimary.svg';
import { ReactComponent as Planner } from './Planner.svg';
import { ReactComponent as Chart } from './Chart.svg';
import { ReactComponent as Idea } from './Idea.svg';
import { ReactComponent as AccountCircle } from './AccountCircle.svg';
import { ReactComponent as TodoList } from './TodoList.svg';
import { ReactComponent as Wallet } from './Wallet.svg';
import { ReactComponent as Money } from './Money.svg';
import { ReactComponent as GoBack } from './GoBack.svg';
import { ReactComponent as Users } from './Users.svg';
import { ReactComponent as Saved } from './Saved.svg';
import { ReactComponent as More } from './More.svg';
import { ReactComponent as Sort } from './Sort.svg';
import { ReactComponent as Help } from './Help.svg';
import { ReactComponent as HomeActive } from './HomeActive.svg';
import { ReactComponent as UsersActive } from './UsersActive.svg';
import { ReactComponent as Bill } from './Bill.svg';
import { ReactComponent as BillActive } from './BillActive.svg';
import { ReactComponent as Diagram } from './Diagram.svg';
import { ReactComponent as DiagramActive } from './DiagramActive.svg';
import { ReactComponent as ID } from './ID.svg';
import { ReactComponent as Map } from './Map.svg';
import { ReactComponent as MapActive } from './MapActive.svg';
import { ReactComponent as Navigation } from './Navigation.svg';
import { ReactComponent as Banknote } from './Banknote.svg';
import { ReactComponent as BanknoteActive } from './BanknoteActive.svg';
import { ReactComponent as Document } from './Document.svg';
import { ReactComponent as DocumentActive } from './DocumentActive.svg';
import { ReactComponent as Health } from './Health.svg';
import { ReactComponent as HealthActive } from './HealthActive.svg';
import { ReactComponent as MoneyBag } from './MoneyBag.svg';
import { ReactComponent as Monitor } from './Monitor.svg';
import { ReactComponent as File } from './File.svg';
import { ReactComponent as FileActive } from './FileActive.svg';
import { ReactComponent as Finance } from './Finance.svg';
import { ReactComponent as FinanceActive } from './FinanceActive.svg';

const Icon = {
  Checked,
  Hidden,
  Visible,
  Error,
  EmailLarge,
  CheckedLarge,
  Exit,
  Home,
  Section,
  Down,
  Plus,
  Edit,
  Search,
  Arrow,
  EditLarge,
  Close,
  Filter,
  Next,
  NextStep,
  Step,
  StepActive,
  StepSuccess,
  Prev,
  Calendar,
  Delete,
  AddPrimary,
  Planner,
  Chart,
  Idea,
  AccountCircle,
  TodoList,
  Wallet,
  Money,
  GoBack,
  Users,
  Saved,
  More,
  Sort,
  Help,
  HomeActive,
  UsersActive,
  Bill,
  BillActive,
  Diagram,
  DiagramActive,
  ID,
  Map,
  MapActive,
  Navigation,
  Banknote,
  BanknoteActive,
  Document,
  DocumentActive,
  Health,
  HealthActive,
  MoneyBag,
  Monitor,
  File,
  FileActive,
  Finance,
  FinanceActive,
};

export type IconType = keyof typeof Icon;

export default Icon;
