import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  label: {
    fontWeight: 500,
  },
  container: {
    width: '100%',
  },
  error: {
    margin: 0,
    fontSize: '14px',
  },
  input: {
    backgroundColor: 'background.paper',
  },
  inputInForm: {
    backgroundColor: 'transparent',
    fontSize: 14,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
    },
  },
  icon: {
    mr: 2,
  },
});

export default style;
