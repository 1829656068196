import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Section from '@/components/ui/Section';
import InlineTable from '@/components/ui/InlineTable';
import { BarChart } from '@mui/x-charts';
import { SXStyleSheet } from '@/utils';
import Image from '@/components/ui/Image';
import { useCallback, useMemo, useReducer } from 'react';
import CellTextInput from '@/components/ui/CellTextInput';
import { euro, futureValue } from '@/utils/math';
import { find, isNaN, map } from 'lodash';
import currency from 'currency.js';
import Decimal from 'decimal.js';

const defaultsCalculations = {
  bsc: '20000',
  cost: '1.0',
  return: '40',
  percent: '1.5',
};

const uData = [1587, 11337];
const pData = [9600, 9600];
const xLabels = ['Kredit', 'ETF-Police'];

interface CalculatorState {
  bsc: string;
  cost: string;
  return: string;
  percent: string;
}

type Action = {
  type: 'update_value';
  payload: {
    key: keyof CalculatorState;
    value: string;
  };
};

export const calculatorReducer = (state: CalculatorState, action: Action) => {
  if (action.type === 'update_value') {
    return {
      ...state,
      [action.payload.key]: action.payload.value,
    };
  }
  throw Error('Unknown action.');
};

const BSCView = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(calculatorReducer, defaultsCalculations);

  const getCostValue = useCallback(
    (value: string) => {
      return euro(state.bsc).multiply(parseFloat(value) / 100);
    },
    [state.bsc],
  );

  const calculatedValue = useMemo(() => {
    const capitalArray: number[] = new Array(160).fill(0);

    const saldoCapital = map(capitalArray, (_: number, i) => {
      const capitalValue = futureValue(
        new Decimal(parseFloat(state.percent)).div(100).div(12).toNumber(),
        new Decimal(i + 1).mul(12).toNumber(),
        -new Decimal(parseFloat(state.return)).toNumber(),
      );

      const multiplyValue = currency(state.return)
        .multiply(12)
        .multiply(i + 1);

      const saldo = currency(-getCostValue(state.cost).value)
        .subtract(multiplyValue)
        .add(capitalValue.toNumber());

      return {
        term: i + 1,
        saldo: saldo.value,
      };
    });

    const foundPositiveSaldo = find(saldoCapital, (value) => value.saldo > 0);

    const charges = futureValue(
      new Decimal(parseFloat(state.percent)).div(100).div(12).toNumber(),
      new Decimal(foundPositiveSaldo?.term || 0).mul(12).toNumber(),
      -new Decimal(parseFloat(state.return)).toNumber(),
    )
      .sub(
        new Decimal(parseFloat(state.return))
          .mul(12)
          .mul(foundPositiveSaldo?.term || 0),
      )
      .toNumber();

    return {
      term: foundPositiveSaldo?.term,
      charges: euro(!isNaN(charges) ? charges : 0).format(),
      profit: euro(foundPositiveSaldo?.saldo || 0).format(),
    };
  }, [getCostValue, state.cost, state.percent, state.return]);

  return (
    <Stack flex={1} px={6} pb={6} gap={6} overflow="auto">
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:bsc.section1.title')}
          </Typography>
          <InlineTable
            data={state}
            mapKeysToTitle={{
              bsc: t('customer:bsc.section1.tableKeys.savingAmount'),
              cost: t('customer:bsc.section1.tableKeys.cost'),
              return: t('customer:bsc.section1.tableKeys.return'),
              percent: t('customer:bsc.section1.tableKeys.duration'),
            }}
            customTableCell={{
              bsc: (
                <CellTextInput
                  value={state.bsc}
                  onTextChange={(value) =>
                    dispatch({
                      type: 'update_value',
                      payload: {
                        key: 'bsc',
                        value,
                      },
                    })
                  }
                  valueType="currency"
                  inTable
                />
              ),
              cost: (
                <CellTextInput
                  value={state.cost}
                  onTextChange={(value) =>
                    dispatch({
                      type: 'update_value',
                      payload: {
                        key: 'cost',
                        value,
                      },
                    })
                  }
                  mapValue={(value) => getCostValue(value).format()}
                  inTable
                />
              ),
              return: (
                <CellTextInput
                  value={state.return}
                  onTextChange={(value) =>
                    dispatch({
                      type: 'update_value',
                      payload: {
                        key: 'return',
                        value,
                      },
                    })
                  }
                  valueType="currency"
                  inTable
                />
              ),
              percent: (
                <CellTextInput
                  value={state.percent}
                  onTextChange={(value) =>
                    dispatch({
                      type: 'update_value',
                      payload: {
                        key: 'percent',
                        value,
                      },
                    })
                  }
                  valueType="percent"
                  inTable
                />
              ),
            }}
          />
        </Stack>
      </Section>
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:bsc.section2.title')}
          </Typography>
          <InlineTable
            data={calculatedValue}
            mapKeysToTitle={{
              term: t('customer:bsc.section2.tableKeys.term'),
              charges: t('customer:bsc.section2.tableKeys.charges'),
              profit: t('customer:bsc.section2.tableKeys.profit'),
            }}
          />
        </Stack>
      </Section>
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:bsc.section3.title')}
          </Typography>
          <Stack sx={style.barChartContainer}>
            <BarChart
              height={536}
              margin={{ bottom: 102 }}
              sx={style.chart}
              grid={{ horizontal: true }}
              borderRadius={8}
              barLabel={(item) => {
                return `${item.value?.toString()} €`;
              }}
              slotProps={{
                legend: {
                  position: { vertical: 'bottom', horizontal: 'middle' },
                  padding: { top: 100 },
                  itemGap: 48,
                },
              }}
              series={[
                { data: pData, label: 'Zinsen', color: '#00ABEA', stack: 'A' },
                {
                  data: uData,
                  label: 'Einzahlungen',
                  color: '#85D7F5',
                  stack: 'A',
                },
              ]}
              xAxis={[
                {
                  scaleType: 'band',
                  data: xLabels,
                  disableLine: true,
                  tickLabelStyle: {
                    display: 'none',
                  },
                },
              ]}
              yAxis={[
                {
                  disableLine: true,
                  disableTicks: true,
                  tickNumber: 4,
                },
              ]}
            />
          </Stack>
        </Stack>
      </Section>
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:bsc.section4.title')}
          </Typography>
          <Typography variant="body1">
            {t('customer:bsc.section4.title')}
          </Typography>
          <Stack flex={1} alignItems="center" justifyContent="center">
            <Image image="Analytics" sx={style.image} />
          </Stack>
        </Stack>
      </Section>
      <Section>
        <Stack gap="19px">
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              {t('customer:bsc.section5.title')}
            </Typography>
            <Image image="SternLogo" />
          </Stack>
          <Typography variant="body1">
            {t('customer:bsc.section5.text')}
          </Typography>
        </Stack>
      </Section>
      <Stack direction="row" gap={1}>
        <Stack flex={3}>
          <Section>
            <Stack pb="29px" gap={2}>
              <Typography variant="subtitle2">
                {t('customer:bsc.section6.title')}
              </Typography>
              <Typography variant="body1">
                {t('customer:bsc.section6.subtitle')}
              </Typography>
            </Stack>
            <Image image="AnalyticsBarChart" />
          </Section>
        </Stack>
        <Stack flex={1}>
          <Section>
            <Stack pb="29px" gap={2}>
              <Typography variant="subtitle2">
                {t('customer:bsc.section7.title')}
              </Typography>
              <Typography variant="body1">
                {t('customer:bsc.section7.subtitle')}
              </Typography>
            </Stack>
            <Image image="AnalyticsPieChart" />
          </Section>
        </Stack>
      </Stack>
    </Stack>
  );
};

const style = SXStyleSheet.create({
  chart: {
    '& .MuiChartsLegend-mark': {
      rx: '4px',
    },
    '& .MuiChartsAxis-tick': {
      stroke: 'transparent !important',
    },
    '& .MuiBarLabel-root': {
      fontSize: '16px',
      fill: '#FFFFFF',
      fontWeight: 600,
    },
  },
  image: {
    width: '100%',
  },
  barChartContainer: {
    backgroundColor: 'background.paper',
    borderRadius: 2,
    padding: 6,
    paddingBottom: 2,
  },
});

export default BSCView;
