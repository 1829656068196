import { TableCell, TableHead } from '@mui/material';
import { FC, useCallback } from 'react';
import { InlineTableHeaderProps } from './types';
import { map } from 'lodash';

const InlineTableHeader: FC<InlineTableHeaderProps> = ({ headerTitles }) => {
  const renderTableHeaderItem = useCallback(
    (item: string, i: number) => {
      return (
        <TableCell width={`${100 / headerTitles.length}%`} key={i}>
          {item}
        </TableCell>
      );
    },
    [headerTitles.length],
  );

  return <TableHead>{map(headerTitles, renderTableHeaderItem)}</TableHead>;
};

export default InlineTableHeader;
