import network from '@/services/network';
import {
  PaginatedResponse,
  Customer,
  ApiResponse,
  InsuranceCreate,
  Insurance,
  SearchParams,
} from '@/types';
import { ErrorResponse } from '@/utils';
import { normalizeData } from '@/utils/system';

const getCustomers = async (params: SearchParams) => {
  try {
    const { data } = await network.get<PaginatedResponse<Customer>>(
      '/customers',
      {
        params,
      },
    );

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const getCustomer = async (id: string) => {
  try {
    const {
      data: { data },
    } = await network.get<ApiResponse<Partial<Customer>>>(`/customers/${id}`);

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const createCustomer = async (
  customer?: Partial<Customer>,
): Promise<Partial<Customer>> => {
  try {
    const {
      data: { data },
    } = await network.post<ApiResponse<Partial<Customer>>>(
      '/customers',
      customer
        ? normalizeData(customer, ['id', 'user_id', 'updated_at', 'created_at'])
        : {},
    );
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const updateCustomer = async (id: string, customer: Partial<Customer>) => {
  try {
    const {
      data: { data },
    } = await network.put<ApiResponse<Partial<Customer>>>(
      `/customers/${id}`,
      normalizeData(customer, ['id', 'user_id', 'updated_at', 'created_at']),
    );

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const getFundingsByCustomerId = async (id: string) => {
  try {
    const {
      data: { data },
    } = await network.get<ApiResponse<Partial<Customer>>>(
      `/customers/${id}/fundings`,
    );
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const getInsurances = async (customerId: string) => {
  try {
    const {
      data: { data },
    } = await network.get<ApiResponse<PaginatedResponse<Insurance>>>(
      `/customers/${customerId}/insurances`,
    );
    return data.data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const addInsurance = async (
  customerId: string,
  insurance: Partial<InsuranceCreate>,
) => {
  try {
    const {
      data: { data },
    } = await network.post<ApiResponse<Partial<Customer>>>(
      `customers/${customerId}/insurances`,
      insurance,
    );
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const customer = {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
  getFundingsByCustomerId,
  getInsurances,
  addInsurance,
};

export default customer;
