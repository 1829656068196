import { Stack, Typography } from '@mui/material';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import TableKeyValue from '@/components/ui/TableKeyValue';
import { BarChart } from '@mui/x-charts';
import { SXStyleSheet } from '@/utils';
import { euro, pmt } from '@/utils/math';

// @todo: add translations
const xLabels = ['Kredit', 'ETF-Police'];

const mockSq = 60;

//=-PMT(7%/12,20*12,0,F18)

const HousePayment = () => {
  const { t } = useTranslation();

  const capital = euro(mockSq).multiply(1600).multiply(1.5).divide(4);
  const creditRate = euro(-pmt(0.07 / 12, 10 * 12, capital.value));
  const creditSum = creditRate.multiply(10).multiply(12);
  const creditDebt = capital.subtract(creditSum);
  const savings = euro(-pmt(0.07 / 12, 20 * 12, 0, capital.value));
  const savingsRate = savings.multiply(12).multiply(20);

  const creditData = [capital.value, savingsRate.value];
  const savingData = [creditDebt.value, 0];
  const investData = [0, capital.subtract(savingsRate).value];

  return (
    <Stack px={6} pb={6} gap={6} overflow="auto">
      <Section>
        <Stack gap={1}>
          <Typography>{t('customer:housePayment.section1.title')}</Typography>
          <Typography variant="subtitle2">
            {t('customer:housePayment.section1.text')}
          </Typography>
        </Stack>
      </Section>
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:housePayment.section2.title', { sq: mockSq })}
          </Typography>
          <TableKeyValue
            header={[
              t('customer:housePayment.tableHead.formula'),
              t('customer:housePayment.tableHead.formulaRepresentation'),
            ]}
            data={[
              [t('customer:housePayment.tableKeys.capital'), capital.format()],
            ]}
          />
        </Stack>
      </Section>
      <Section>
        <TableKeyValue
          data={[
            [t('customer:housePayment.tableKeys.rate'), creditRate.format()],
            [
              t('customer:housePayment.tableKeys.saving0'),
              capital.divide(20).divide(12).format(),
            ],
            [
              t('customer:housePayment.tableKeys.saving7'),
              euro(-pmt(0.07 / 12, 20 * 12, 0, capital.value)).format(),
            ],
          ]}
        />
      </Section>
      <Section>
        <Stack gap={1}>
          <Typography>{t('customer:housePayment.section4.title')}</Typography>
          <Typography variant="subtitle2">
            {t('customer:housePayment.section4.text')}
          </Typography>
        </Stack>
      </Section>
      <Section>
        <Stack gap="29px">
          <Typography variant="subtitle2">
            {t('customer:housePayment.section5.title')}
          </Typography>
          <Stack sx={style.barChartContainer}>
            <BarChart
              height={536}
              margin={{ bottom: 102 }}
              sx={style.chart}
              grid={{ horizontal: true }}
              borderRadius={8}
              barLabel={(item) => {
                if (!item.value) return '';
                return euro(item.value as number).format();
              }}
              slotProps={{
                legend: {
                  position: { vertical: 'bottom', horizontal: 'middle' },
                  padding: { top: 100 },
                  itemGap: 48,
                },
              }}
              series={[
                {
                  data: creditData,
                  label: 'Rendite',
                  color: '#00ABEA',
                  stack: 'A',
                },
                {
                  data: savingData,
                  label: 'Zinsen',
                  color: '#D33F33',
                  stack: 'A',
                },
                {
                  data: investData,
                  label: 'Eigenbeitrag',
                  color: '#85D7F5',
                  stack: 'A',
                },
              ]}
              xAxis={[
                {
                  scaleType: 'band',
                  data: xLabels,
                  disableLine: true,
                  tickSize: 24,
                },
              ]}
              yAxis={[
                {
                  disableLine: true,
                  disableTicks: true,
                  tickNumber: 4,
                },
              ]}
            />
          </Stack>
        </Stack>
      </Section>
    </Stack>
  );
};

const style = SXStyleSheet.create({
  chart: {
    '& .MuiChartsLegend-mark': {
      rx: '4px',
    },
    '& .MuiChartsAxis-tick': {
      stroke: 'transparent !important',
    },
    '& .MuiBarLabel-root': {
      fontSize: '16px',
      fill: '#FFFFFF',
      fontWeight: 600,
    },
  },
  barChartContainer: {
    backgroundColor: 'background.paper',
    borderRadius: 2,
    padding: 6,
    paddingBottom: 2,
  },
});

export default HousePayment;
