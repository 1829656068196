import { createTheme } from '@mui/material';
import { MuiOutlinedInputStyle } from './input';

const theme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: '"Inter", sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '38px',
      fontOpticalSizing: 'auto',
    },
    h2: {
      fontWeight: 600,
      fontSize: '28px',
      lineHeight: '38px',
      fontOpticalSizing: 'auto',
    },
    h3: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '24px',
      fontOpticalSizing: 'auto',
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      fontOpticalSizing: 'auto',
      whiteSpace: 'pre-line',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      fontOpticalSizing: 'auto',
      whiteSpace: 'pre-line',
    },
    button: {
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'none',
      fontOpticalSizing: 'auto',
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '28px',
      fontOpticalSizing: 'auto',
    },
    subtitle2: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '28px',
      color: '#101828',
      fontOpticalSizing: 'auto',
    },
    overline: {
      fontSize: '16px',
      color: '#B3B3B5',
    },
    caption: {
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      hover: '#EBF6FA',
      darker: '#04465B',
      dark: '#04526B',
      main: '#036A8B',
      contrastText: '#FFFFFF',
      light: '#E6F1F5',
      altLight: '#2EBFD0',
      altLighter: '#B9E8F1',
    },
    background: {
      screen: '#E5EDF4',
      card: '#F1F8FF',
    },
    secondary: {
      main: '#F59418',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#ECFDF3',
      main: '#ECFDF3',
      contrastText: '#027A48',
    },
    error: {
      light: '#FEF3F2',
      main: '#F04438',
      contrastText: '#B42318',
    },
    text: {
      primary: '#475467',
      secondary: '#101828',
    },
    grey: {
      50: '#EEEEEE',
      100: '#D0D5DD',
      200: '#B3B3B5',
      800: '#475467',
      900: '#101828',
      A100: '#D8E1EB',
      A200: '#FCFCFD',
      A400: '#2D303A',
      A700: '#111726',
    },
    divider: '#E4E7EC',
  },
  components: {
    MuiOutlinedInput: MuiOutlinedInputStyle,
  },
});

export default theme;
