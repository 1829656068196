import { useMatches } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import Header from '@/components/Header';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { some } from 'lodash';
import { Routes } from '@/constants/routes';
import Section from '@/components/ui/Section';
import PropertyInsarance from '@/components/tables/PropertyInsurance';

const FinanceInfoView = () => {
  const { t } = useTranslation();
  const matches = useMatches();

  const isNewCustomer = useMemo(() => {
    return some(matches, (match) => {
      return match.pathname.includes(Routes.NewCustomer);
    });
  }, [matches]);

  return (
    <Stack flex={1}>
      <Header
        title={t(
          !isNewCustomer
            ? 'customer:customerDetails.title'
            : 'customer:newCustomer.title',
        )}
        subtitle={t('customer:financeInfo.title')}
      />
      <Stack px={6} pb={6} gap={6} overflow="auto">
        <Section>
          <Stack gap="19px">
            <Typography variant="subtitle2">
              {t('customer:financeInfo.section1.title')}
            </Typography>
            <PropertyInsarance />
          </Stack>
        </Section>
      </Stack>
    </Stack>
  );
};

export default FinanceInfoView;
