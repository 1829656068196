import { TableCell, TableHead } from '@mui/material';
import { TableKeyValueHeader } from '@/components/ui/TableKeyValue/types';
import { FC } from 'react';

export interface TableHeaderProps {
  data: TableKeyValueHeader;
}

const TableHeader: FC<TableHeaderProps> = ({ data }) => {
  return (
    <TableHead>
      <TableCell>{data[0]}</TableCell>
      <TableCell>{data[1]}</TableCell>
    </TableHead>
  );
};

export default TableHeader;
