import { FC, FocusEventHandler, useMemo, useState } from 'react';

import TextInput, { TextInputProps } from '@/components/ui/TextInput';

import { TableCell, TableCellProps } from '@mui/material';
import { euro } from '@/utils/math';

export interface TextFieldProps extends TextInputProps {
  tableCellProps?: TableCellProps;
  valueType?: 'value' | 'currency' | 'percent';
  mapValue?: (value: string) => string;
}

const CellTextInput: FC<TextFieldProps> = ({
  tableCellProps = {},
  valueType,
  onBlur,
  value,
  mapValue,
  ...props
}) => {
  const [editing, setEditing] = useState(false);

  const handleClick = () => {
    setEditing(true);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setEditing(false);
    onBlur?.(e);
  };

  const displayValue = useMemo(() => {
    switch (valueType) {
      case 'currency':
        return euro(value).format();
      case 'percent':
        return `${value} %`;
      case 'value':
        return value;
      default:
        return value;
    }
  }, [value, valueType]);

  return (
    <TableCell {...tableCellProps} onClick={handleClick}>
      {editing ? (
        <TextInput
          {...props}
          value={value}
          autoWidth
          onBlur={handleBlur}
          inTable
        />
      ) : mapValue ? (
        mapValue(value)
      ) : (
        displayValue
      )}
    </TableCell>
  );
};

export default CellTextInput;
