import { FC, FocusEventHandler, useCallback, useState } from 'react';

import TextInput, { TextInputProps } from '@/components/ui/TextInput';

import useFormField from '@/hooks/useFormField';
import { TableCell, TableCellProps } from '@mui/material';
import { compact, join } from 'lodash';

export interface TextFieldProps
  extends Omit<TextInputProps, 'value' | 'error' | 'onTextChange' | 'touched'> {
  tableCellProps?: TableCellProps;
  fieldName: string;
  inline?: boolean;
  valueEndAdornment?: string;
}

const CellTextField: FC<TextFieldProps> = ({
  fieldName,
  inline,
  tableCellProps = {},
  valueEndAdornment,
  onBlur: onBlurCallback,
  ...props
}) => {
  const [editing, setEditing] = useState(false);

  const { value, error, setValue, onBlur, touched } = useFormField<string>(
    fieldName,
    '',
  );

  const handleTextChange = useCallback(
    (text: string) => {
      setValue(text);
    },
    [setValue],
  );

  const handleClick = () => {
    setEditing(true);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setEditing(false);
    onBlur(e);
    onBlurCallback?.(e);
  };

  return (
    <TableCell {...tableCellProps} onClick={handleClick}>
      {editing ? (
        <TextInput
          {...props}
          autoWidth
          error={error}
          value={value}
          onTextChange={handleTextChange}
          onBlur={handleBlur}
          touched={touched}
          inTable
        />
      ) : (
        join(compact([value, !!value && valueEndAdornment]), '')
      )}
    </TableCell>
  );
};

export default CellTextField;
