import { SXStyleSheet } from '@/utils';
import {
  tableCellClasses,
  tableHeadClasses,
  tableRowClasses,
} from '@mui/material';

const style = SXStyleSheet.create({
  table: {
    borderRadius: 2,
    overflow: 'hidden',
    [`& .${tableHeadClasses.root}`]: {
      [`& .${tableCellClasses.root}`]: {
        fontSize: 14,
        height: 44,
        p: 0,
        px: '24px',
        color: 'primary.contrastText',
      },
      [`& .${tableCellClasses.root}:nth-child(odd)`]: {
        backgroundColor: '#1AA0B0',
        fontWeight: 600,
      },
      [`& .${tableCellClasses.root}:nth-child(even)`]: {
        backgroundColor: '#1EB2C3',
        fontWeight: 500,
      },
    },
    [`& .${tableRowClasses.root}`]: {
      [`& .${tableCellClasses.root}`]: {
        fontSize: 14,
        fontWeight: 600,
        p: 0,
        px: '24px',
        height: '72px',
      },
      [`& .${tableCellClasses.root}:nth-child(odd)`]: {
        backgroundColor: '#E9F4FB',
      },
      [`& .${tableCellClasses.root}:nth-child(even)`]: {
        backgroundColor: '#FFFFFF',
      },
    },
  },
});

export default style;
