import { Table, TableCell, TableRow } from '@mui/material';
import style from '@/components/tables/PropertyInsurance/style';
import { useTranslation } from 'react-i18next';
import { euro } from '@/utils/math';
import useFormField from '@/hooks/useFormField';
import { Insurance } from '@/types';

const PropertyInsuranceSum = () => {
  const { t } = useTranslation();

  const { value } = useFormField<Insurance[]>('insurances', []);

  const totalMonth = value.reduce((acc, insurance) => {
    return acc.add(insurance.monthly_payment || 0);
  }, euro(0));

  const totalYear = value.reduce((acc, insurance) => {
    return acc.add(insurance.annual_payment || 0);
  }, euro(0));

  const totalMonthNew = value.reduce((acc, insurance) => {
    return acc.add(insurance.monthly_payment_new || 0);
  }, euro(0));

  const totalYearNew = value.reduce((acc, insurance) => {
    return acc.add(insurance.annual_payment_new || 0);
  }, euro(0));

  const totalMonthResult = totalMonth.add(totalYear.divide(12));
  const totalYearResult = totalYear.add(totalMonth.multiply(12));
  const totalMonthNewResult = totalMonthNew.add(totalYearNew.divide(12));
  const totalYearNewResult = totalYearNew.add(totalMonthNew.multiply(12));

  return (
    <Table sx={style.tableResults}>
      <TableRow>
        <TableCell width="50%">
          {t('customer:financeInfo.table.total')}
        </TableCell>
        <TableCell width="12.5%" className="accent-cell">
          {totalMonthResult.format()}
        </TableCell>
        <TableCell width="12.5%" className="accent-cell">
          {totalYearResult.format()}
        </TableCell>
        <TableCell width="12.5%">{totalMonthNewResult.format()}</TableCell>
        <TableCell width="12.5%">{totalYearNewResult.format()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>{t('customer:financeInfo.table.savings')}</TableCell>
        <TableCell className="saving-cell" colSpan={2}>
          {totalMonthNewResult.subtract(totalMonthResult).multiply(-1).format()}
        </TableCell>
        <TableCell className="saving-cell" colSpan={2}>
          {totalYearNewResult.subtract(totalYearResult).multiply(-1).format()}
        </TableCell>
      </TableRow>
    </Table>
  );
};

export default PropertyInsuranceSum;
