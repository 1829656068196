import currency from 'currency.js';
import Decimal from 'decimal.js';

export const euro = (value: string | number) =>
  currency(value, {
    symbol: '€',
    separator: '.',
    decimal: ',',
    pattern: '# !',
  });

export const pmt = (
  rate: number,
  nper: number,
  pv: number,
  fv = 0,
  type = 0,
) => {
  if (rate === 0) {
    return -(pv + fv) / nper;
  }

  const q = Math.pow(1 + rate, nper);
  return -(rate * (pv * q + fv)) / ((type ? 1 + rate : 1) * (q - 1));
};

export const futureValue = (rate: number, nper: number, pmt: number) => {
  if (rate === 0) {
    // Якщо ставка 0, тоді FV дорівнює сумі всіх платежів.
    return new Decimal(-(pmt * nper));
  }

  // Використовуємо класичну формулу:
  // FV = -pmt * [((1 + rate)^nper - 1) / rate]
  return new Decimal(-pmt * ((Math.pow(1 + rate, nper) - 1) / rate));
};
