import { Outlet, useMatches, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

import NavigationBar from '@/components/ui/NavigationBar';
import { LifePlanSideBar } from '@/constants/sidebar';
import Header from '@/components/Header';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { some } from 'lodash';
import { Routes } from '@/constants/routes';

const LifePlanView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const matches = useMatches();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const isNewCustomer = useMemo(() => {
    return some(matches, (match) => {
      return match.pathname.includes(Routes.NewCustomer);
    });
  }, [matches]);

  return (
    <Stack flex={1} flexDirection="row">
      <NavigationBar items={LifePlanSideBar} onClick={handleNavigate} />
      <Stack flex={1}>
        <Header
          title={t(
            !isNewCustomer
              ? 'customer:customerDetails.title'
              : 'customer:newCustomer.title',
          )}
          subtitle={t('customer:lifeplan.subtitle')}
        />
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default LifePlanView;
