import { SXStyleSheet } from '@/utils';
import {
  tableCellClasses,
  tableHeadClasses,
  tableRowClasses,
} from '@mui/material';

const style = SXStyleSheet.create({
  table: {
    borderRadius: 2,
    overflow: 'hidden',
    [`& .${tableHeadClasses.root}`]: {
      [`& .${tableCellClasses.root}`]: {
        width: '50%',
        fontSize: 14,
      },
      [`& .${tableCellClasses.root}:first-of-type`]: {
        backgroundColor: '#00ABEA',
        color: 'primary.contrastText',
      },
      [`& .${tableCellClasses.root}:last-child`]: {
        backgroundColor: '#E9F4FB',
        fontWeight: 700,
      },
    },
    [`& .${tableRowClasses.root}`]: {
      [`& .${tableCellClasses.root}`]: {
        width: '50%',
        fontSize: 14,
      },
      [`& .${tableCellClasses.root}:first-of-type`]: {
        backgroundColor: '#1EB2C3',
        color: 'primary.contrastText',
      },
      [`& .${tableCellClasses.root}:last-child`]: {
        backgroundColor: 'background.paper',
      },
    },
  },
});

export default style;
