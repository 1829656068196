import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import style from './style';

const Section: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack flex={1} sx={style.container}>
      {children}
    </Stack>
  );
};

export default Section;
