import Logo from './Logo.png';
import Logo2x from './Logo2x.png';
import Insurance from './Insurance.png';
import Analytics from './Analytics.png';
import SternLogo from './SternLogo.png';
import AnalyticsBarChart from './AnalyticsBarChart.png';
import AnalyticsPieChart from './AnalyticsPieChart.png';

const Image = {
  Logo,
  Insurance,
  Logo2x,
  Analytics,
  SternLogo,
  AnalyticsBarChart,
  AnalyticsPieChart,
};

export type ImageType = keyof typeof Image;

export default Image;
