import { useCallback, useEffect, useRef } from 'react';

const useDebounce = <T extends Function>(cb: T, delay: number) => {
  const timeout = useRef<NodeJS.Timeout>();
  const callbackRef = useRef(cb);

  useEffect(() => {
    callbackRef.current = cb;
  }, [cb]);

  const debouncedFn = useCallback(
    (params: unknown[]) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        callbackRef.current(params);
      }, delay);
    },
    [delay],
  );

  return debouncedFn as unknown as T;
};

export default useDebounce;
