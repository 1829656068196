import { SingleSelectItem } from '@/components/ui/SingleSelect';

export enum InsurancePropertyLiabilityTypesEnum {
  PERSONAL_LIABILITY_INSURANCE = 'personal_liability_insurance',
  HOUSEHOLD_INSURANCE = 'household_insurance',
  GLASS_INSURANCE = 'glass_insurance',
  HOMEOWNERS_INSURANCE = 'homeowners_insurance',
  CAR_INSURANCE = 'car_insurance',
  LEGAL_PROTECTION_INSURANCE = 'legal_protection_insurance',
  ACCIDENT_INSURANCE = 'accident_insurance',
  PET_OWNER_LIABILITY_INSURANCE = 'pet_owner_liability_insurance',
}

export enum InsuranceLifeFinancialTypesEnum {
  OCCUPATIONAL_DISABILITY_INSURANCE = 'occupational_disability_insurance',
  BASIC_SKILLS_INSURANCE = 'basic_skills_insurance',
  RISK_LIFE_INSURANCE = 'risk_life_insurance',
  CAPITAL_FORMING_LIFE_INSURANCE = 'capital_forming_life_insurance',
  BASIS_RURUP_PENSION = 'basis_rurup_pension',
  RIESTER_PENSION = 'riester_pension',
  COMPANY_PENSION_SCHEME = 'company_pension_scheme',
  FUND_POLICY = 'fund_policy',
  INDEX_POLICY = 'index_policy',
  ETF_POLICY = 'etf_policy',
  HOME_SAVINGS_CONTRACT = 'home_savings_contract',
  DAILY_MONEY_ACCOUNT = 'daily_money_account',
  SAVINGS_BOOK = 'savings_book',
  STOCK_DEPOT = 'stock_depot',
  CRYPTO_DEPOT = 'crypto_depot',
}

export const LifeInsuranceList: SingleSelectItem[] = [
  {
    label: 'Berufsunfähigkeitsversicherung',
    value: InsuranceLifeFinancialTypesEnum.OCCUPATIONAL_DISABILITY_INSURANCE,
  },
  {
    label: 'Grundfähigkeitenversicherung',
    value: InsuranceLifeFinancialTypesEnum.BASIC_SKILLS_INSURANCE,
  },
  {
    label: 'Risikolebensversicherung',
    value: InsuranceLifeFinancialTypesEnum.RISK_LIFE_INSURANCE,
  },
  {
    label: 'Kapitalbildende Lebensversicherung',
    value: InsuranceLifeFinancialTypesEnum.CAPITAL_FORMING_LIFE_INSURANCE,
  },
  {
    label: 'Basis/Rürup-Rente',
    value: InsuranceLifeFinancialTypesEnum.BASIS_RURUP_PENSION,
  },
  {
    label: 'Riester-Rente',
    value: InsuranceLifeFinancialTypesEnum.RIESTER_PENSION,
  },
  {
    label: 'Betriebliche Altersvorsorge',
    value: InsuranceLifeFinancialTypesEnum.COMPANY_PENSION_SCHEME,
  },
  {
    label: 'Fondspolice',
    value: InsuranceLifeFinancialTypesEnum.FUND_POLICY,
  },
  {
    label: 'Indexpolice',
    value: InsuranceLifeFinancialTypesEnum.INDEX_POLICY,
  },
  {
    label: 'ETF-Police',
    value: InsuranceLifeFinancialTypesEnum.ETF_POLICY,
  },
  {
    label: 'Bausparvertrag',
    value: InsuranceLifeFinancialTypesEnum.HOME_SAVINGS_CONTRACT,
  },
  {
    label: 'Tagesgeldkonto',
    value: InsuranceLifeFinancialTypesEnum.DAILY_MONEY_ACCOUNT,
  },
  {
    label: 'Sparbuch',
    value: InsuranceLifeFinancialTypesEnum.SAVINGS_BOOK,
  },
  {
    label: 'Aktien-Depot',
    value: InsuranceLifeFinancialTypesEnum.STOCK_DEPOT,
  },
  {
    label: 'Krypto-Depot',
    value: InsuranceLifeFinancialTypesEnum.CRYPTO_DEPOT,
  },
];

export const PropertyInsuranceList: SingleSelectItem[] = [
  {
    label: 'Privathaftpflichtversicherung',
    value: InsurancePropertyLiabilityTypesEnum.PERSONAL_LIABILITY_INSURANCE,
  },
  {
    label: 'Hausratversicherung',
    value: InsurancePropertyLiabilityTypesEnum.HOUSEHOLD_INSURANCE,
  },
  {
    label: 'Glasversicherung',
    value: InsurancePropertyLiabilityTypesEnum.GLASS_INSURANCE,
  },
  {
    label: 'Wohngebäudeversicherung',
    value: InsurancePropertyLiabilityTypesEnum.HOMEOWNERS_INSURANCE,
  },
  {
    label: 'KFZ-Versicherung',
    value: InsurancePropertyLiabilityTypesEnum.CAR_INSURANCE,
  },
  {
    label: 'Rechtsschutzversicherung',
    value: InsurancePropertyLiabilityTypesEnum.LEGAL_PROTECTION_INSURANCE,
  },
  {
    label: 'Unfallversicherung',
    value: InsurancePropertyLiabilityTypesEnum.ACCIDENT_INSURANCE,
  },
  {
    label: 'Tierhalterhaftpflichtversicherung',
    value: InsurancePropertyLiabilityTypesEnum.PET_OWNER_LIABILITY_INSURANCE,
  },
];
