import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    padding: 6,
    backgroundColor: '#F2F6FA',
    borderRadius: 3,
  },
});

export default style;
