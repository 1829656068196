import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    width: '260px',
    borderRight: '1px solid #CFD3DA',
    height: '100%',
    px: 4,
    py: 8,
  },
  navItemContainer: {
    justifyContent: 'flex-start',
    height: '40px',
    borderRadius: 2,
    pl: 3,
    color: 'primary.main',
  },
  navContainerActive: {
    backgroundColor: '#CFDFE9',
    '& p': {
      fontWeight: 700,
    },
  },
});

export default style;
