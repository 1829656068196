import { Stack, Typography } from '@mui/material';
import Section from '@/components/ui/Section';
import LifeInsurance from '@/components/tables/LifeInsurance';
import { useTranslation } from 'react-i18next';
import useCustomerStore from '@/store/customer';
import { useEffect } from 'react';

const LifeInsuranceView = () => {
  const { t } = useTranslation();

  const { insurances, getInsurances } = useCustomerStore();

  useEffect(() => {
    getInsurances().catch();
  }, [getInsurances]);

  return (
    <Stack px={6} pb={6} gap={6} overflow="auto">
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:lifeInsurance.section1.title')}
          </Typography>
          <LifeInsurance insurances={insurances || []} />
        </Stack>
      </Section>
    </Stack>
  );
};

export default LifeInsuranceView;
