import { ButtonBase, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { NavigationBarItemProps } from './types';
import Icon from '@/components/ui/Icon';
import { useTranslation } from 'react-i18next';

import style from './style';
import { Routes } from '@/constants/routes';

const NavigationBarItem: FC<NavigationBarItemProps> = ({
  icon,
  label,
  active,
  activeIcon,
  path,
  onClick,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    // @todo: currently mock path is a root path, to avoid exit from customer
    //        we need to add this line of code. Remove after all items will be
    //        implemented correctly
    if (path === Routes.Root) return;
    onClick(path);
  };

  return (
    <ButtonBase
      sx={[style.navItemContainer, active && style.navContainerActive]}
      disableRipple
      onClick={handleClick}
    >
      <Stack direction="row" gap={3}>
        <Icon icon={active ? activeIcon : icon} />
        <Typography>{t(label)}</Typography>
      </Stack>
    </ButtonBase>
  );
};

export default NavigationBarItem;
