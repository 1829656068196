import { TableCell, TableRow } from '@mui/material';
import { useCallback } from 'react';
import { InlineTableRowProps } from '@/components/ui/InlineTable/types';
import { keys, map } from 'lodash';

const InlineTableRow = <T extends object>({
  data,
  customTableCell,
}: InlineTableRowProps<T>) => {
  const renderValueItem = useCallback(
    (key: keyof T, i: number) => {
      if (customTableCell?.[key]) {
        return customTableCell?.[key];
      }
      return <TableCell key={i}>{data[key] as string}</TableCell>;
    },
    [customTableCell, data],
  );

  return <TableRow>{map(keys(data), renderValueItem)}</TableRow>;
};

export default InlineTableRow;
