import SingleSelect, { SingleSelectProps } from '@/components/ui/SingleSelect';
import { FC } from 'react';
import useFormField from '@/hooks/useFormField';
import { TableCell, TableCellProps } from '@mui/material';

export interface SelectFieldProps
  extends Omit<SingleSelectProps, 'value' | 'error'> {
  fieldName: string;
  tableCellProps?: TableCellProps;
  // @todo(KAN-119): investigate how we are going to clear values on change the answer
  onSelect?: (value: string) => void;
}

const CellSelectField: FC<SelectFieldProps> = ({
  fieldName,
  onSelect,
  tableCellProps = {},
  ...props
}) => {
  const { value, error, setValue } = useFormField<string>(fieldName, '');

  const handleSelect = (value: string) => {
    setValue(value);
    onSelect?.(value);
  };

  return (
    <TableCell {...tableCellProps}>
      <SingleSelect
        inTable
        value={value}
        error={error}
        onSelect={handleSelect}
        {...props}
      />
    </TableCell>
  );
};

export default CellSelectField;
