import { Outlet, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

import NavigationBar from '@/components/ui/NavigationBar';
import { FinancesSideBarItems } from '@/constants/sidebar';

const FinanceView = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <Stack flex={1} flexDirection="row">
      <NavigationBar items={FinancesSideBarItems} onClick={handleNavigate} />
      <Outlet />
    </Stack>
  );
};

export default FinanceView;
