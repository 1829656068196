export const Routes = {
  Root: '/',
  Home: 'home',
  Auth: 'auth',
  SignIn: 'sign-in',
  ResetPasswordEmail: 'reset-password-email',
  ResetPasswordSuccess: 'reset-password-success',
  PasswordReset: 'password-reset',
  ForgotPassword: 'forgot-password',
  Admin: 'admin',
  Customers: 'customers',
  NewCustomer: 'new-customer',
  Customer: 'customer',
  CustomerDetails: 'customer-details',
  LifePlan: 'life-plan',
  HousePayment: 'house-payment',
  Planner: 'planner',
  BSC: 'bsc',
  LifeInsurance: 'life-insurance',
  Finance: 'finance',
  FinanceInfo: 'finance-info',
};

export const CompanyRoute = 'https://softverysolutions.com';
