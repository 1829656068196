import { TableCell, TableRow } from '@mui/material';
import CellSelectField from '@/components/formControl/CellSelectField';
import { PropertyInsuranceList } from '@/constants/insurances';
import CellTextField from '@/components/formControl/CellTextField';
import IconButton from '@/components/ui/IconButton';
import { FC } from 'react';
import { getFormKey } from '@/utils/system';

export interface PropertyInsuranceRowProps {
  formKey: string;
  index: number;
  onDelete: (i: number) => void;
}

// @todo: rename field names according to api
const PropertyInsuranceRow: FC<PropertyInsuranceRowProps> = ({
  onDelete,
  index,
  formKey,
}) => {
  const handleDelete = () => {
    onDelete(index);
  };

  return (
    <TableRow>
      <CellSelectField
        tableCellProps={{ width: 274 }}
        fieldName={getFormKey(formKey, 'fieldName1')}
        items={PropertyInsuranceList}
      />
      <CellTextField
        tableCellProps={{ width: '15%' }}
        fieldName={getFormKey(formKey, 'fieldName2')}
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'monthly_payment')}
        endAdornment="€"
        valueEndAdornment="€"
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'annual_payment')}
        endAdornment="€"
        valueEndAdornment="€"
      />
      <CellTextField
        tableCellProps={{ width: '15%' }}
        fieldName={getFormKey(formKey, 'fieldName5')}
      />
      <CellTextField
        tableCellProps={{ width: 100 }}
        fieldName={getFormKey(formKey, 'monthly_payment_new')}
        endAdornment="€"
        valueEndAdornment="€"
      />
      <CellTextField
        tableCellProps={{ width: 100 }}
        fieldName={getFormKey(formKey, 'annual_payment_new')}
        endAdornment="€"
        valueEndAdornment="€"
      />
      <TableCell width="50px">
        <IconButton onClick={handleDelete} icon="Delete" variant="primary" />
      </TableCell>
    </TableRow>
  );
};

export default PropertyInsuranceRow;
