import { TableCell, TableRow } from '@mui/material';
import { TableKeyValueData } from '@/components/ui/TableKeyValue/types';
import { FC } from 'react';
import { map } from 'lodash';

export interface KeyValueTableRowProps {
  data: TableKeyValueData;
}

const KeyValueTableRow: FC<KeyValueTableRowProps> = ({ data }) => {
  const renderCells = (value: string, index: number) => {
    return <TableCell key={index}>{value}</TableCell>;
  };

  return <TableRow>{map(data, renderCells)}</TableRow>;
};

export default KeyValueTableRow;
