import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import Image from '@/assets/images';
import { SXStyleSheet } from '@/utils';

export interface NewCustomerHeaderProps {
  title: string;
  subtitle?: string;
}

const Header: FC<NewCustomerHeaderProps> = ({ title, subtitle }) => {
  return (
    <Stack
      px={8}
      py={6}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack gap={4.5}>
        <Typography variant="h2" color="text.secondary">
          {title}
        </Typography>
        {!!subtitle && (
          <Typography variant="h3" color="text.secondary">
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Box
        component="img"
        src={Image.Logo}
        alt="logo"
        sx={style.logoContainer}
      />
    </Stack>
  );
};

const style = SXStyleSheet.create({
  logoContainer: {
    height: '78px',
  },
});

export default Header;
