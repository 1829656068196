import Network from '@/services/network';
import {
  CreateUserRequest,
  CreateUserResponse,
  PaginatedResponse,
  SearchParams,
  User,
} from '@/types';
import { ErrorResponse } from '@/utils';
import network from '@/services/network';

const getUsers = async (params: SearchParams) => {
  try {
    const { data } = await Network.get<PaginatedResponse<User>>('/users', {
      params,
    });
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const createUser = async (
  user: CreateUserRequest,
): Promise<CreateUserResponse> => {
  try {
    const { data } = await network.post<CreateUserResponse>('/users', user);

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const user = {
  getUsers,
  createUser,
};

export default user;
