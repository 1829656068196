import { Divider, Stack, Table, TableCell, TableHead } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './style';
import { FieldArray, Formik } from 'formik';
import { map, noop } from 'lodash';
import { FC } from 'react';
import { Insurance } from '@/types';
import LifeInsuranceRow from '@/components/tables/LifeInsurance/LifeInsuranceRow';
import AddInsurance from '@/components/tables/LifeInsurance/AddInsurance';
import SumRow from '@/components/tables/LifeInsurance/SumRow';
import { getFormKey } from '@/utils/system';

export interface LifeInsuranceProps {
  insurances: Insurance[];
}

const LifeInsurance: FC<LifeInsuranceProps> = () => {
  const { t } = useTranslation();

  // @todo: implement with API
  // const formRef = useRef<FormikProps<{ insurances: Insurance[] }>>(null);
  //
  // useEffect(() => {
  //   formRef.current?.setValues({ insurances });
  // }, [insurances]);

  return (
    <Formik initialValues={{ insurances: [] }} onSubmit={noop}>
      <Stack>
        <Table sx={style.table}>
          <TableHead>
            <TableCell width="274px">
              {t('customer:lifeInsurance.table.insurance')}
            </TableCell>
            <TableCell width="20%">
              {t('customer:lifeInsurance.table.company')}
            </TableCell>
            <TableCell width="255px" className="no-padding" colSpan={2}>
              <Stack width="100%" height="100%" alignItems="center">
                <Stack justifyContent="center" flex={1}>
                  {t('customer:lifeInsurance.table.contribution')}
                </Stack>
                <Stack
                  sx={style.dividedCell}
                  width="100%"
                  direction="row"
                  alignItems="center"
                  gap={2}
                  flex={1}
                >
                  <Stack flex={1} alignItems="center">
                    {t('customer:lifeInsurance.table.monthly')}
                  </Stack>
                  <Divider color="divider" sx={style.divider} />
                  <Stack flex={1} alignItems="center">
                    {t('customer:lifeInsurance.table.yearly')}
                  </Stack>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell width="20%">
              {t('customer:lifeInsurance.table.info')}
            </TableCell>
            <TableCell width="50px">
              {t('customer:lifeInsurance.table.action')}
            </TableCell>
          </TableHead>
          <FieldArray
            name="insurances"
            render={(helpers) => {
              return map(helpers.form.values.insurances, (_, i) => (
                <LifeInsuranceRow
                  index={i}
                  formKey={getFormKey('insurances', i)}
                  onDelete={helpers.remove}
                />
              ));
            }}
          />
          <SumRow />
        </Table>
        <AddInsurance />
      </Stack>
    </Formik>
  );
};

export default LifeInsurance;
